import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Submittedpage = ({ data }) => {
  return (
    <Layout classExtra="nofooter">
      <SEO
        title="Submitted"
        keywords={[
          `australian`,
          `exclusive`,
          `jewellery`,
          `engagement`,
          `wedding`,
        ]}
      />
      <div className="blogwrap">
        <div className="contactbackground" />
        <div className="formfloat">
          <div className="contactsuccess">
            <div className="formcenter">
              Thanks, we look forward to contacting you shortly.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Submittedpage
